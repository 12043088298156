<template>
  <PublicLayout>
    <div class="con1 center">
      <img src="@images/public_home/pic3.svg" alt="" class="bg" />
      <p class="title">
        <img src="@images/public_home/icon1.svg" alt="" />
        <span>DRIVING SAFETY ONTARIO</span>
      </p>
      <div class="text-title">
        <p @click="open = !open">
          <img src="@images/public_home/icon2.svg" alt="" v-show="!open" />
          <img src="@images/public_home/icon6.svg" alt="" v-show="open" />
        </p>
        <span>DRIVING SAFETY ONTARIO</span>
      </div>
      <transition name="slide">
        <div class="text" v-show="open">
          <p>
            Designed with management and learner experience in mind, our content
            engineers backed by our UI/UX designers created a seamless yet
            stunning learning environment allowing the Beginner Driver Education
            course to be easily-digestible and delivered on any device for a
            truly embedded learning experience.
          </p>
          <p>
            DriveSafe’s modern design revolutionizes the BDE course with
            staggering new graphics and visuals allowing for a dynamic and
            captivating online learning experience for students and a seamless
            management system for driving schools. From administration,
            documentation, tracking and reporting, to training, development and
            delivery of the BDE, DriveSafe provides the ultimate one-stop
            platform catered to any and all driving school needs.
          </p>
        </div>
      </transition>
      <div class="share">
        <div class="icon">
          <img src="@images/public_home/facebook.svg" alt="" />
          <img src="@images/public_home/twitter.svg" alt="" />
          <img src="@images/public_home/youtube.svg" alt="" />
          <img src="@images/public_home/instagram.svg" alt="" />
          <p></p>
        </div>
      </div>
    </div>
    <div class="con2">
      <div class="center">
        <img src="@images/public_home/pic1.png" alt="" />
        <div class="left">
          <p class="title">Are you a driving school operator?</p>
          <p class="text">
            See how you can shift your business to a digital platform.
          </p>
          <p class="btn" @click="navigatoSignUp">Create an Account</p>
        </div>
      </div>
    </div>
    <div class="con3 center">
      <div class="box">
        <img src="@images/public_home/pic2.png" alt="" />
        <ul>
          <li>
            <img src="@images/public_home/icon3.svg" alt="" />
            <div class="right">
              <p>Online Courses</p>
              <p>Learn driving skills from anywhere</p>
            </div>
            <p class="bottom"></p>
          </li>
          <li>
            <img src="@images/public_home/icon4.svg" alt="" />
            <div class="right">
              <p>Expert Instruction</p>
              <p>Your key to safe driving</p>
            </div>
            <p class="bottom"></p>
          </li>
          <li>
            <img src="@images/public_home/icon5.svg" alt="" />
            <div class="right">
              <p>One Year Full Access</p>
              <p>Make the most of it</p>
            </div>
            <p class="bottom"></p>
          </li>
        </ul>
      </div>
    </div>
    <div class="con4 center">
      <p class="title">SUPPORTS</p>
      <ul>
        <li>
          <p class="title">TECHNICAL SUPPORT</p>
          <p class="text">
            Technical support with issues regarding program, software solution,
            login failure etc.
          </p>
          <p class="text">Availability: Mon - Fri 9am to 6pm EST</p>
        </li>
        <li>
          <p class="title">SERVICE SUPPORT</p>
          <p class="text">
            Service support provided to address course user experience,
            complaints, etc.
          </p>
          <p class="text">Availability: Everyday From 9am to 6pm EST</p>
        </li>
        <li>
          <p class="title">COURSE CONTENT<br />SUPPORT</p>
          <p class="text">
            Please contact your driving school for all content support.
          </p>
          <p class="text">Availability: Monday to Friday 9am-5pm EST</p>
        </li>
      </ul>
    </div>
    <div class="con5">
      <div class="center">
        <p class="text">
          The purpose of this program is to provide you with the knowledge,
          skills, and attitudes needed to drive your vehicle safely.
        </p>
        <div class="icon">
          <img src="@images/public_home/facebook-small.svg" alt="" />
          <img src="@images/public_home/twitter-small.svg" alt="" />
          <img src="@images/public_home/youtube-small.svg" alt="" />
          <img src="@images/public_home/instagram-small.svg" alt="" />
          <p></p>
        </div>
        <template v-if="emails !== null">
          <p class="phone" v-if="emails.phone !== null">
            <img src="@images/public_home/phone.svg" alt="" />
            <span>Call or text: +1{{ emails.phone }}</span>
          </p>
          <p class="email">
            <img src="@images/public_home/email.svg" alt="" />
            <span>{{ emails.service_email }}</span>
          </p>
        </template>
      </div>
    </div>
  </PublicLayout>
</template>

<script>
// @ is an alias to /src
import PublicLayout from "@/components/PublicLayout.vue";

export default {
  name: "Home",
  components: {
    PublicLayout,
  },
  data() {
    return {
      open: true,
      emails: null,
    };
  },
  methods: {
    navigatoSignUp() {
      // conso
      window.location.href = `${window.location.origin}/school/sign_up.html`;
      // window.location.reload();
    },
  },
  async created() {
    console.log(window.location.origin);
    const res = await this.axios.get("/email/emailHost");
    //  console.log(res);
    this.emails = res.data;
  },
};
</script>
<style lang="scss" scoped>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
img {
  display: block;
}
.con1 {
  position: relative;
  padding-top: 104px;
  .bg {
    position: absolute;
    right: 0;
    top: 72px;
  }
  .title {
    display: flex;
    align-items: center;
    span {
      font-family: SFProDisplay;
      font-size: 40px;
      margin-left: 32px;
    }
  }
  .text-title {
    display: flex;
    align-items: center;
    margin-top: 22px;
    span {
      font-size: 24px;
      margin-left: 32px;
    }
    img {
      cursor: pointer;
    }
  }
  .text {
    overflow: hidden;
    transition: all 0.3s ease;
    margin-top: 41px;
    p {
      font-size: 18px;
      line-height: 22px;
    }
    p:last-child {
      margin-top: 26px;
    }
  }
  .share {
    overflow: hidden;
    display: flex;
    justify-content: flex-end;

    margin-top: 200px;
    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 217px;
      img {
        margin-left: 27px;
        cursor: pointer;
      }
      p {
        width: 126px;
        height: 3px;
        background: #29384b;
        margin-top: 28px;
      }
    }
  }
}
.con2 {
  padding: 148px 0 165px;
  background-color: #29384b;
  margin-top: 174px;
  .center {
    overflow: hidden;
    img {
      float: right;
    }
    .left {
      color: #e9edee;
      margin-top: 415px;
      .title {
        font-size: 50px;
        font-family: SFProDisplay;
      }
      .text {
        font-size: 20px;
        margin-top: 15px;
      }
      .btn {
        width: 344px;
        height: 45px;
        background: #9daab3;
        margin-top: 33px;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background-color: #8896a0;
        }
      }
    }
  }
}
.con3 {
  .box {
    width: 703px;
    overflow: hidden;
    margin-top: -40px;
  }
  ul {
    padding: 71px 0 50px;
    background-color: #9daab3;
    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 571px;
      margin: 0 auto 14px;
      border-bottom: 25px solid
        linear-gradient(180deg, #84929d 0%, #9daab3 100%);
      img {
        margin-left: 19px;
      }
      .right {
        font-size: 18px;
        color: #fff;
        margin-left: 24px;
      }
      .bottom {
        width: 571px;
        height: 25px;
        background: linear-gradient(180deg, #84929d 0%, #9daab3 100%);
        margin-top: 15px;
      }
    }
  }
}
.con4 {
  margin-top: 146px;
  overflow-x: auto;
  > .title {
    position: relative;
    font-size: 50px;
    color: #29384b;
    font-family: SFProDisplay;
    &::after {
      position: absolute;
      left: 0;
      bottom: -20px;
      content: "";
      width: 126px;
      height: 3px;
      background: #29384b;
    }
  }
  ul {
    margin-top: 105px;
    @include flex(center, space-between);
    li {
      flex: 1;
      height: 361px;
      background: #9daab3;
      padding-left: 32px;
      color: #fff;
      list-style: none;
      .title {
        font-size: 20px;
        padding-top: 91px;
        padding-bottom: 50px;
        line-height: 25px;
      }
      .text {
        width: 280px;
        font-size: 18px;
        padding-bottom: 23px;
        line-height: 22px;
      }
      &:last-child {
        .title {
          padding-top: 79px;
          padding-bottom: 37px;
        }
      }
    }
  }
}
.con5 {
  padding: 88px 0;
  background-color: #e9edee;
  margin-top: 153px;
  .text {
    width: 730px;
    text-align: center;
    font-size: 18px;
    margin: auto;
  }
  .phone,
  .email {
    width: 250px;
    @include flex(center, "");
    margin: auto;
    img {
      margin-right: 18px;
    }
  }
  .email {
    margin-top: 14px;
  }
  .icon {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 56px;
    margin-top: 10px;
    img {
      margin-right: 15px;
      cursor: pointer;
    }
  }
}
</style>
