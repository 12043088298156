<template>
  <transition name="fade">
    <div class="top" v-show="top > 300" @click="toTop">
      <img src="@images/up-icon.svg" alt="" />
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      top: 0,
    }
  },
  methods: {
    getScrollTop() {
      var scroll_top = 0
      if (document.documentElement && document.documentElement.scrollTop) {
        scroll_top = document.documentElement.scrollTop
      } else if (document.body) {
        scroll_top = document.body.scrollTop
      }
      this.top = scroll_top
    },
    toTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    },
  },
  mounted() {
    this.getScrollTop()
    window.addEventListener("scroll", this.getScrollTop)
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.getScrollTop)
  },
}
</script>
<style lang="scss" scoped>
.top {
  position: fixed;
  bottom: 25px;
  right: 20px;
  width: 67px;
  height: 67px;
  border-radius: 50%;
  background-color: #e9edee;
  @include flex(center, center);
  cursor: pointer;
  &:hover {
    background-color: #9daab3;
  }
}
</style>
