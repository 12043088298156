<template>
  <div class="publicLayout-wrapper">
    <div class="header">
      <div class="con">
        <img
          src="@images/logo-small.svg"
          alt=""
          class="logo"
          @click="$router.push('/')"
        />
        <div class="right">
          <p @click="$router.push('/')">Home</p>
          <p @click="$router.push('/about')">About Us</p>
          <p @click="$router.push('/faqs')">FAQs</p>
          <div class="login" @click="navigatoStudent">
            <img src="@images/user-b.svg" alt="" />
            <div class="txt">
              <p>Log In</p>
              <p>Student</p>
            </div>
          </div>
          <div class="login" @click="navigatoSchool">
            <img src="@images/flag.svg" alt="" />
            <div class="txt">
              <p>Log In</p>
              <p>School</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
    <div class="footer">
      <div class="con">
        <p class="left">© Copyrights Drivesafe Courses All rights reserved.</p>
        <div class="right">
          <span @click="$router.push('/about')">About Us</span>
          <span @click="$router.push('/prvacypolicy')">Privacy Policy</span>
          <span @click="$router.push('/termsofuse')">Terms & Conditions</span>
          <span @click="$router.push('faqs')">FAQs</span>
        </div>
      </div>
    </div>
    <ToTop />
  </div>
</template>
<script>
import ToTop from "@c/ToTop";
export default {
  components: {
    ToTop,
  },
  data() {
    return {
      user: JSON.parse(sessionStorage.getItem("useer")),
    };
  },
  methods: {
    navigatoSchool() {
      window.location.href = `${window.location.origin}/school/sign_in.html`;
    },
    navigatoStudent() {
      window.location.href = `${window.location.origin}/student/sign_in.html`;
    },
  },
};
</script>
<style lang="scss" scoped>
.publicLayout-wrapper {
  min-width: 100%;
  overflow: auto;
  .header {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    height: 70px;
    background-color: #29384b;
    .con {
      @include flex(center, space-between);
      width: 100%;
      max-width: 1280px;
      padding: 0 20px;
      height: 100%;
      margin: auto;
      .logo {
        width: 114px;
        cursor: pointer;
      }
      .right {
        @include flex(center, center);
        p {
          margin-left: 38px;
          font-size: 16px;
          color: #fff;
          cursor: pointer;
        }
        .login {
          @include flex(center, center);
          width: auto;
          height: 37px;
          border-radius: 10px;
          border: 1px solid #ffffff;
          transition: all 0.3s;
          padding: 0 10px;
          margin-left: 38px;
          font-size: 16px;
          color: #fff;
          img {
            width: 24px;
            margin-right: 10px;
          }
          &:hover {
            background-color: #fff;
            color: #29384b;
            p {
              color: #29384b;
            }
            img {
              filter: invert(1);
            }
          }
          .txt {
            p {
              margin-left: 0;
              font-size: 18px;
              &:first-child {
                font-size: 12px;
                float: right;
              }
            }
          }
        }
      }
    }
  }
  .content {
    min-height: calc(100vh - 70px - 104px);
  }
  .footer {
    width: 100%;
    height: 104px;
    background-color: #29384b;
    .con {
      @include flex(center, space-between);
      width: 100%;
      max-width: 1280px;
      padding: 0 20px;
      height: 100%;
      margin: auto;
      color: #fff;
      .right {
        @include flex(center, center);
        span {
          margin-left: 38px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
