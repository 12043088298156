import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import "@/assets/css/base.scss"
import VueAxios from 'vue-axios'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import installElementPlus from './plugins/element'

const app = createApp(App);

app.use(router);
app.use(VueAxios, axios);
// app.use(ElementPlus, { size: 'small'});
installElementPlus(app)

axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "https://beta.drivesafeontario.ca/api/v1/" : '/api';
axios.defaults.retry = 4;
axios.defaults.retryDelay = 1000;
axios.defaults.timeout = 8000;

app.mount('#app');
